*{
    box-sizing: border-box;
}

.joystick{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.joystick_row{
    display: flex;
    justify-content: center;
    gap: 70px;
}

.joystick_btn{
    width: 50px;
    height: 50px;
}

body{
    height: 100vh;
    margin: 0;
}

.all-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    justify-content: space-between;
    padding-bottom: 10px;
    gap: 10px;
}

.xone-wrapper{
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-line{
    display: flex;
    justify-content: center;
}